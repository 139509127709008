import React from "react";
import { Link } from "gatsby";
import { SanityImage } from "@components/sanity/sanityImage";
import cx from "classnames";
import { motion } from "framer-motion";
import { fadeUp } from "@lib/animation";
import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { AnchorNav } from "../../anchorNav";

const FullImageBlock = ({
  bgImage,
  logo,
  hideLogo,
  optionalLink,
  config,
  isHeader,
  optionalText,
  anchorMenu,
}) => {
  const navRef = React.useRef(null);
  const bannerRef = React.useRef(null);

  const [navActive, setNavActive] = React.useState(false);

  //detect when navRef is at the top of the page
  React.useEffect(() => {
    const handleScroll = () => {
      if (navRef?.current?.getBoundingClientRect()?.top <= 0) {
        setNavActive(true);
      } else {
        setNavActive(false);
      }
    };
    const handleScroll2 = () => {
      if (bannerRef?.current?.getBoundingClientRect().bottom >= 0) {
        setNavActive(false);
      } else {
        setNavActive(true);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    window.addEventListener("scroll", handleScroll2, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", handleScroll2);
    };
  }, [navRef]);

  //get height of navRef
  const [navHeight, setNavHeight] = React.useState(0);
  React.useEffect(() => {
    setNavHeight(
      navRef.current ? navRef?.current?.getBoundingClientRect().height : 0
    );
  }, [navRef]);

  return (
    <ModuleWrapper {...config}>
      <div
        className={cx(" w-full relative ", {
          "h-screen": isHeader,
          "h-full": !isHeader,
        })}
        ref={bannerRef}
      >
        {bgImage?.asset?.url && (
          <img
            style={{ width: "100%" }}
            loading="lazy"
            src={bgImage?.asset?.url}
            className={cx(
              "absolute w-full h-full object-cover lg:h-full  top-0 left-0 ",
              {
                absolute: isHeader,
                relative: !isHeader,
              }
            )}
          />
        )}
        {optionalText && (
          <div className="absolute top-1/2 transform  -translate-y-1/2 left-0 z-10 text-white mt-20 px-gutter w-2/3">
            <PortableTextBlock text={optionalText} />
          </div>
        )}
        {!isHeader && logo && !hideLogo && (
          <Link
            to={optionalLink}
            target="_blank"
            className="w-20  lg:w-48 mx-auto absolute z-20 bottom-2 lg:bottom-10 right-2 lg:right-20  py-4"
          >
            <SanityImage className=" " image={logo} />
          </Link>
        )}
        <div
          className={cx("absolute top-0 left-0 w-full h-full bg-black", {
            "bg-opacity-50": optionalText,
            "bg-opacity-20": !optionalText,
          })}
        />
      </div>
      {isHeader && logo && !hideLogo && (
        <Link
          to={optionalLink}
          target="_blank"
          className="w-48 mx-auto absolute z-20 bottom-6 lg:bottom-10 right-8 lg:right-20 lg:mr-0 lg:w-60 py-16"
        >
          <SanityImage className=" " image={logo} />
        </Link>
      )}
      {/* Sticky nav */}
      {anchorMenu.length !== 0 && (
        <AnchorNav
          navRef={navRef}
          anchorMenu={anchorMenu}
          navActive={navActive}
        />
      )}
    </ModuleWrapper>
  );
};

export default FullImageBlock;
